import Layout from '../../layouts'
import * as React from 'react'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Container } from '../../components'
import { theme } from '../../assets/theme'
import { useTranslation } from '..'
const Podvorie = ({ data, pageContext: context, location }) => {
  console.log(data.strapiPodvorie)
  const { description } = data.strapiPodvorie
  const [title, content] = description.split('/links/')
  const str = content.replace(
    /\/uploads\//,
    'https://admin.afonpodvorie.com/uploads/',
  )
  const { t } = useTranslation(context?.locale)
  return (
    <Layout
      data={data.strapiPodvorie}
      meta={context?.meta}
      menu={context?.menu}
      locale={context?.locale}
      location={location}>
      <SContainer>
        <SReactMarkdown skipHtml>{title}</SReactMarkdown>
        <LinksList>
          {data.strapiPodvorie.link.map(item => (
            <LinksItem key={item.id}>
              {item?.icon ? (
                <ItemIcon
                  src={`https://admin.afonpodvorie.com${item.icon.url}`}
                  alt={item?.title}
                />
              ) : (
                ''
              )}
              {item?.title ? <span>{item?.title}</span> : ''}
              {item?.description ? <p>{item?.description}</p> : ''}
              {item?.link ? (
                <SLink to={item?.link}>{t('Читать далее')}</SLink>
              ) : (
                ''
              )}
            </LinksItem>
          ))}
        </LinksList>
        <SReactMarkdown skipHtml>{str}</SReactMarkdown>
      </SContainer>
    </Layout>
  )
}

export const query = graphql`
  query HistoryTemplate($locale: String!) {
    strapiPodvorie(locale: { eq: $locale }) {
      id
      title
      description
      background {
        url
      }
      link {
        title
        icon {
          url
        }
        description
        link
      }
    }
  }
`

const SLink = styled(Link)`
  position: relative;
  color: ${theme.colors.primary};
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -3px;
    height: 2px;
    background: ${theme.colors.primary};
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover {
    &:after {
      transform: translateY(-3px);
    }
  }
`

const ItemIcon = styled.img``

const LinksItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  span {
    margin-top: 1rem;
  }
`

const SContainer = styled(Container)`
  flex-direction: column;
`

const LinksList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 4rem;
  line-height: 30px;

  p {
    overflow: hidden;
    -webkit-box-orient: vertical;
    line-height: 30px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
  }

  span {
    font-size: 24px;
  }
`

const SReactMarkdown = styled(ReactMarkdown)`
  width: 100%;
  margin: 4rem 0;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2.2rem;
    margin: 0;
    margin-bottom: 2rem;
  }

  h6 {
    display: block;
    padding: 30px;
    font-weight: normal;
    background: #cac297;
    line-height: 28px;
  }

  img {
    width: 100%;
  }

  ul {
    list-style-type: none;

    li {
      position: relative;
      line-height: 28px;
      font-size: 18px;
      &:marker {
        content: '';
        display: none;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -1.5rem;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 99999px;
        width: 8px;
        height: 8px;
        background: #cac297;
      }
    }
  }

  blockquote {
    position: relative;
    margin: 30px 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 100%;
      background: #cac297;
    }
    & > p {
      margin: 0;
      margin-left: 2rem;
      font-size: 36px;
      line-height: 46px;

      ${() => theme.mqMax('lg')} {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
`

export default Podvorie
